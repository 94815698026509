import React, { useState, useEffect } from "react";

const PerformaCheck = ({ labelledby, offcanvasid, handelSubmit, viewData }) => {
  const [nonVerbalCommunication, setNonVerbalCommunication] = useState({
    eyeContact: "",
    positiveBodyLanguage: "",
    enthusiasm: "",
    noFidgeting: "",
    professionalAppearance: "",
  });

  const [verbalCommunication, setVerbalComunication] = useState({
    aviodFilter: "",
    conciseResponses: "",
    uniqueSkills: "",
  });

  const [technicalComunication, setTechnicalCommunication] = useState({
    domainKnowledge: "",
    pastJObExp: "",
    skills: "",
  });

  const [anyCommpents, setAnyCommpents] = useState("");

  useEffect(() => {
    console.log("viewData: ", viewData);
    if (viewData.length !== 0) {
      setNonVerbalCommunication(viewData && viewData[0].nonVerbal);
      setVerbalComunication(viewData[1].verbal);
      setTechnicalCommunication(viewData[2].techincal);
      setAnyCommpents(viewData[3].coments);
    }
  }, [viewData]);

  return (
    <>
      <div
        className="offcanvas offcanvas-bottom"
        tabIndex={-1}
        id={offcanvasid}
        aria-labelledby={labelledby}
        style={{ width: "65%", height: "100%", marginLeft: "18%" }}
      >
        <div className="offcanvas-header border-bottom">
          <h5 id={labelledby}>Check Performa</h5>
          <button
            type="button"
            id="closeButton"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body" style={{ margin: "2%" }}>
          <div className="row">
            <div className="col">
              <h6>Non-Verbal Communication</h6>
            </div>
            <div className="col-2">
              <p>Excelent</p>
            </div>
            <div className="col-2">
              <p>Good</p>
            </div>
            <div className="col-2">
              <p>Need Improvement</p>
            </div>
            <div className="col-2">
              <p>N/A</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Mantained eye contact</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="eyeContact"
                value="Excellent"
                checked={nonVerbalCommunication.eyeContact === "Excellent"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="eyeContact"
                value="Good"
                checked={nonVerbalCommunication.eyeContact === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="eyeContact"
                value="Need Improvement"
                checked={
                  nonVerbalCommunication.eyeContact === "Need Improvement"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="eyeContact"
                value="N/A"
                checked={nonVerbalCommunication.eyeContact === "N/A"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Used positive body language</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="positiveBodyLanguage"
                value="Excellent"
                checked={
                  nonVerbalCommunication.positiveBodyLanguage === "Excellent"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="positiveBodyLanguage"
                value="Good"
                checked={nonVerbalCommunication.positiveBodyLanguage === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="positiveBodyLanguage"
                value="Need Improvement"
                checked={
                  nonVerbalCommunication.positiveBodyLanguage ===
                  "Need Improvement"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="positiveBodyLanguage"
                value="N/A"
                checked={nonVerbalCommunication.positiveBodyLanguage === "N/A"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Conveyed ecnthusiasm and intrest</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="enthusiasm"
                value="Excellent"
                checked={nonVerbalCommunication.enthusiasm === "Excellent"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="enthusiasm"
                value="Good"
                checked={nonVerbalCommunication.enthusiasm === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="enthusiasm"
                value="Need Improvement"
                checked={
                  nonVerbalCommunication.enthusiasm === "Need Improvement"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="enthusiasm"
                value="N/A"
                checked={nonVerbalCommunication.enthusiasm === "N/A"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Refrained from fidgeting or nervous movemnts</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="noFidgeting"
                value="Excellent"
                checked={nonVerbalCommunication.noFidgeting === "Excellent"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="noFidgeting"
                value="Good"
                checked={nonVerbalCommunication.noFidgeting === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="noFidgeting"
                value="Need Improvement"
                checked={
                  nonVerbalCommunication.noFidgeting === "Need Improvement"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="noFidgeting"
                value="N/A"
                checked={nonVerbalCommunication.noFidgeting === "N/A"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>
                Demonstrated professionals appearance and appropriate attire
              </p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="professionalAppearance"
                value="Excellent"
                checked={
                  nonVerbalCommunication.professionalAppearance === "Excellent"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="professionalAppearance"
                value="Good"
                checked={
                  nonVerbalCommunication.professionalAppearance === "Good"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="professionalAppearance"
                value="Need Improvement"
                checked={
                  nonVerbalCommunication.professionalAppearance ===
                  "Need Improvement"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="professionalAppearance"
                value="N/A"
                checked={
                  nonVerbalCommunication.professionalAppearance === "N/A"
                }
              />
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col">
              <h6>Verbal Communication</h6>
            </div>
            <div className="col-2">
              <p>Excelent</p>
            </div>
            <div className="col-2">
              <p>Good</p>
            </div>
            <div className="col-2">
              <p>Need Improvement</p>
            </div>
            <div className="col-2">
              <p>N/A</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Avoided filter words (Umm,like,I means)</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="aviodFilter"
                value="Excellent"
                checked={verbalCommunication.aviodFilter === "Excellent"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="aviodFilter"
                value="Good"
                checked={verbalCommunication.aviodFilter === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="aviodFilter"
                value="Need Improvement"
                checked={verbalCommunication.aviodFilter === "Need Improvement"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="aviodFilter"
                value="N/A"
                checked={verbalCommunication.aviodFilter === "N/A"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Provide clear and concise responses</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="conciseResponses"
                value="Excellent"
                checked={verbalCommunication.conciseResponses === "Excellent"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="conciseResponses"
                value="Good"
                checked={verbalCommunication.conciseResponses === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="conciseResponses"
                value="Need Improvement"
                checked={
                  verbalCommunication.conciseResponses === "Need Improvement"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="conciseResponses"
                value="N/A"
                checked={verbalCommunication.conciseResponses === "N/A"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Emphasized strengths and highlighted unique skills</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="uniqueSkills"
                value="Excellent"
                checked={verbalCommunication.uniqueSkills === "Excellent"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="uniqueSkills"
                value="Good"
                checked={verbalCommunication.uniqueSkills === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="uniqueSkills"
                value="Need Improvement"
                checked={
                  verbalCommunication.uniqueSkills === "Need Improvement"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="uniqueSkills"
                value="N/A"
                checked={verbalCommunication.uniqueSkills === "N/A"}
              />
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col">
              <h6>Technical Communication</h6>
            </div>
            <div className="col-2">
              <p>Excelent</p>
            </div>
            <div className="col-2">
              <p>Good</p>
            </div>
            <div className="col-2">
              <p>Need Improvement</p>
            </div>
            <div className="col-2">
              <p>N/A</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Domain Knoweldege</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="domainKnowledge"
                value="Excellent"
                checked={technicalComunication.domainKnowledge === "Excellent"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="domainKnowledge"
                value="Good"
                checked={technicalComunication.domainKnowledge === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="domainKnowledge"
                value="Need Improvement"
                checked={
                  technicalComunication.domainKnowledge === "Need Improvement"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="domainKnowledge"
                value="N/A"
                checked={technicalComunication.domainKnowledge === "N/A"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Past Job Experience</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="pastJObExp"
                value="Excellent"
                checked={technicalComunication.pastJObExp === "Excellent"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="pastJObExp"
                value="Good"
                checked={technicalComunication.pastJObExp === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="pastJObExp"
                value="Need Improvement"
                checked={
                  technicalComunication.pastJObExp === "Need Improvement"
                }
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="pastJObExp"
                value="N/A"
                checked={technicalComunication.pastJObExp === "N/A"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>Skills</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="skills"
                value="Excellent"
                checked={technicalComunication.skills === "Excellent"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="skills"
                value="Good"
                checked={technicalComunication.skills === "Good"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="skills"
                value="Need Improvement"
                checked={technicalComunication.skills === "Need Improvement"}
              />
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="skills"
                value="N/A"
                checked={technicalComunication.skills === "N/A"}
              />
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-4">
              <h6>Any Comments:</h6>
            </div>
            <div className="col">
              <textarea
                style={{ width: "90%", height: 130 }}
                value={anyCommpents}
                onChange={(e) => setAnyCommpents(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformaCheck;
