import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import Sidebar from "../../Layouts/Sidebar";
import LectureService from "../../../Services/LectureService";
import DataParser from "../../../utlits/Dataparser";

export const ViewLecture = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();
  const id = params.id;

  const userIUd = DataParser.UserId();
  const [trainingId, setTraingId] = useState("");
  const [lectureTitle, setLectureTitle] = useState("");
  const [lectureDetails, setLectureDetails] = useState("");
  const [lectureUrl, setLectureUrl] = useState("");

  const updateLectureapi = (e) => {
    const data = {
      title: lectureTitle,
      short_des: lectureDetails,
      youtubeLink: lectureUrl,
      created_by: userIUd,
      status: "pending",
    };
    LectureService.updateLectures(id, data).then((res) => {
      console.log("res: ", res);
      navigate(`/${DataParser.CompanyIdentifierRole()}/lecture`, {
        state: { data: trainingId },
      });
      getLectures(trainingId);
    });
  };

  const getLectures = (uid) => {
    LectureService.showAllLectures(uid).then((res) => {
      console.log("res: ", res);
    });
  };

  const LectureView = () => {
    LectureService.viewLectures(id).then((res) => {
      console.log("res: ", res);
      const data = res.data.lecture[0];
      setLectureTitle(data.title);
      setLectureDetails(data.short_des);
      setLectureUrl(data.youtubeLink);
      setTraingId(data.training_id);
    });
  };

  useEffect(() => {
    LectureView();
  }, []);

  return (
    <>
      <Helmet>
        <title>Instructor - Lecture</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Lecture</h4>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Lecture Title
                        </label>
                        <input
                          value={lectureTitle}
                          onChange={(e) => setLectureTitle(e.target.value)}
                          name="lectureTitle"
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Lecture Details
                        </label>
                        <ReactQuill
                          theme="snow"
                          value={lectureDetails}
                          onChange={setLectureDetails}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Lecture Url
                        </label>
                        <input
                          value={lectureUrl}
                          onChange={(e) => setLectureUrl(e.target.value)}
                          name="lectureUrl"
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <button
                        onClick={(e) => updateLectureapi(e)}
                        type="submit"
                        className="btn btn-primary glow"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
