import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CompanyService from "../../../Services/CompanyService";
import InstructorService from "../../../Services/InstructorService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import AddInstructor from "./AddInstructor";
import ViewInstructor from "./ViewInstructor";

const Instructor = () => {
  const companyId = DataParser.CompanyId();
  const [instructorList, setInstructorList] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);
  const [editId, setEditId] = useState("");

  const getInstructors = (cid) => {
    InstructorService.getallInstructor(cid).then((res) => {
      const data = res.data.Instructors;
      setInstructorList(data);
    });
  };

  const getCompanyUsers = () => {
    CompanyService.getComapnyUser(companyId).then((res) => {
      const data = res.data.user;
      setCompanyUserList(data);
    });
  };

  useEffect(() => {
    getInstructors(companyId);
    getCompanyUsers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Instructor - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <AddInstructor
              id={"offcanvasRightInstructor"}
              labelledby={"offcanvasRightInstructorLabel"}
              getInstructors={getInstructors}
            />
            <ViewInstructor
              editId={editId}
              id={"offcanvasRightEditInstructor"}
              labelledby={"offcanvasRightEditInstructorLabel"}
              getInstructors={getInstructors}
            />
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Instructor</h4>
                  <div className="page-title-right">
                    <Link to="#">
                      <span
                        type="button"
                        className="btn btnsss waves-effect waves-light"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRightInstructor"
                        aria-controls="offcanvasRightInstructor"
                      >
                        Add Instructor
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <table id="dataEable" className="table mb-0 table-striped">
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>Name</th>
                        <th>Created By</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {instructorList.map((el, index) => (
                        <tr
                          role="row"
                          className={index % 2 === 0 ? "odd" : "even"}
                        >
                          <td className="tableset">{el.name}</td>
                          <td className="tableset">
                            {
                              companyUserList?.find(
                                (ase) => ase.id === el.created_by
                              )?.first_name
                            }
                            &nbsp;
                            {
                              companyUserList?.find(
                                (ase) => ase.id === el.created_by
                              )?.last_name
                            }
                          </td>
                          <td className="tableset">
                            <Link
                              onClick={(e) => setEditId(el.id)}
                              to="#"
                              data-bs-toggle="offcanvas"
                              data-bs-target={`#offcanvasRightEditInstructor`}
                              aria-controls={`offcanvasRightEditInstructor`}
                            >
                              <i className="uil-pen iconscolor" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instructor;
