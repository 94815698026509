import React from "react";
import { Routes, Route } from "react-router-dom";
import ForgotPassword from "./Components/Screens/Auth/ForgotPassword";
import Login from "./Components/Screens/Auth/Login";
import ResetPassword from "./Components/Screens/Auth/ResetPassword";
import Career from "./Components/Screens/Career/Career";
import Main from "./Main/Main";

const App = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          exact
          path="/reset-password/:token"
          element={<ResetPassword />}
        />
      </Routes>
      <Main />
    </>
  );
};

export default App;
