import React, { useState, useEffect } from "react";
import CompanyService from "../../../Services/CompanyService";
import InstructorService from "../../../Services/InstructorService";
import DataParser from "../../../utlits/Dataparser";

const AddInstructor = ({ id, labelledby, getInstructors }) => {
  const companyId = DataParser.CompanyId();
  const userId = DataParser.UserId();

  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectUserName, setSelectedUserName] = useState("");

  const [companyUserList, setCompanyUserList] = useState([]);

  const getCompanyUsers = () => {
    CompanyService.getComapnyUser(companyId).then((res) => {
      const data = res.data.user;
      setCompanyUserList(data);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const link = document.getElementById("closeInstructor");
    const body = {
      company_id: companyId,
      name: selectUserName,
      created_by: userId,
      user_id: selectedUserId,
    };
    InstructorService.addInstructor(body).then((res) => {
      link.click();
      getInstructors(companyId);
    });
  };

  useEffect(() => {
    getCompanyUsers(companyId);
  }, []);
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id={id}
        aria-labelledby={labelledby}
        style={{ width: "600px" }}
      >
        <div className="offcanvas-header border-bottom">
          <h5 id={labelledby}>Add Instructor</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id="closeInstructor"
          />
        </div>
        <div className="offcanvas-body">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
              <div className="row" style={{ paddingRight: "2rem" }}>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Name<span className="alert_star">*</span>
                      </label>
                    </div>

                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={selectUserName}
                        onChange={(e) => setSelectedUserName(e.target.value)}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Select Instructor<span className="alert_star">*</span>
                      </label>
                    </div>

                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        name="NAMES"
                        id="basicSelect"
                        value={selectedUserId}
                        onChange={(e) => setSelectedUserId(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        {companyUserList.map((esl) => (
                          <option value={esl.id}>
                            {esl.first_name}&nbsp;{esl.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-4">
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddInstructor;
