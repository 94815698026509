import React, { useState } from "react";
import { Link } from "react-router-dom";

const AddRepater = (props) => {
  const { repeater, setRepeater } = props;

  const addMultiField = (event, index) => {
    event.preventDefault();
    let data = [...repeater];
    let fields = {
      targetname: "",
    };
    data[index]["fields"].push(fields);
    setRepeater(data);
  };

  const removeMultiFields = (e, oldindex, index) => {
    e.preventDefault();
    let data = [...repeater];
    data[oldindex].fields.splice(index, 1);
    setRepeater(data);
  };

  const handleMultiFields = (oldindex, index, event) => {
    let data = [...repeater];
    data[oldindex].fields[index]["targetname"] = event.target.value;
    setRepeater(data);
  };

  const addRepeating = (e) => {
    e.preventDefault();
    let newfields = {
      question: "",
      questionType: "",
      fields: [],
    };
    setRepeater([...repeater, newfields]);
  };

  const handleRepeatingFields = (index, event) => {
    let data = [...repeater];
    data[index][event.target.name] = event.target.value;
    // data[index]['fields'] = multiField;
    setRepeater(data);
  };

  return (
    <>
      <div className="col mb-3" style={{ textAlign: "right" }}>
        <button
          type="button"
          data-repeater-create=""
          onClick={addRepeating}
          className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 add_new_user"
        >
          <i className="icon-plus4" />
          <span>+</span> Add Questions
        </button>
      </div>
      {repeater?.map((el, index) => (
        <>
          <div key={index} className="row">
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label" htmlFor="validationCustom03">
                  Question
                </label>
                <textarea
                  className="form-control d-block  font_12"
                  name="question"
                  value={el.question}
                  onChange={(event) => handleRepeatingFields(index, event)}
                  id="vq_question"
                  placholder="Details"
                  rows={4}
                  style={{ resize: "none" }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label" htmlFor="validationCustom03">
                  Question Type
                </label>
                <select
                  onChange={(event) => handleRepeatingFields(index, event)}
                  value={el.questionType}
                  className="form-control"
                  name="questionType"
                >
                  <option value="">Select Type</option>
                  <option value="textarea">Text Area</option>
                  <option value="Radio Buttons">Radio Buttons</option>
                  <option value="Dropdown List">Drop down List</option>
                  <option value="input">Input Field</option>
                </select>
              </div>
              {!["input", "textarea", ""].includes(el.questionType) ? (
                <>
                  <label for="">
                    <Link
                      onClick={(e) => addMultiField(e, index)}
                      to="#"
                      className="mt-2 mx-2"
                    >
                      <b>Add</b>
                    </Link>{" "}
                    {el.questionType}Option
                  </label>
                  {el?.fields?.map((elsss, index1) => (
                    <div key={index1} className="row mt-2">
                      <div className="col-8">
                        <input
                          id={index1}
                          value={elsss.targetname}
                          onChange={(e) => handleMultiFields(index, index1, e)}
                          type="text"
                          className="form-control"
                          name="targetname"
                        />
                      </div>
                      <div className="col-4">
                        <Link
                          onClick={(e) => {
                            removeMultiFields(e, index, index1);
                          }}
                          to="#"
                          className="font_10 mt-2"
                        >
                          <i
                            className="fas fas-close mt-2 font_20"
                            style={{ color: "#FF4979" }}
                          />
                        </Link>
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default AddRepater;
