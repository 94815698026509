import React, { useState, useEffect } from "react";
import Select from "react-select";
import moment from "moment/moment";
import CompanyService from "../../../Services/CompanyService";
import TrainingServices from "../../../Services/TrainingServices";
import DataParser from "../../../utlits/Dataparser";

const ViewTraining = ({ id, labelledby, getTrainings, editId }) => {
  const companyId = DataParser.CompanyId();
  const userId = DataParser.UserId();

  const [selectedUser, setSelectedUser] = useState("");
  const [allCompanyUser, setAllCompanyUser] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);

  const [trainingDuration, setTrainingDuration] = useState(0);
  const [title, setTitle] = useState("");
  const [targingMode, setTargingMode] = useState("");
  const [targingBudget, setTargingBudget] = useState("");
  const [targingDesc, setTargingDesc] = useState("");
  const [status, setStatus] = useState("");
  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getCompanyUsers = () => {
    CompanyService.getComapnyUser(companyId).then((res) => {
      const data = res.data.user;
      setAllCompanyUser(data);
      let newData = [];

      for (let i = 0; i < data.length; i++) {
        let obj = {
          value: data[i].id,
          label: `${data[i].first_name} ${data[i].last_name}`,
        };
        newData.push(obj);
      }
      setCompanyUserList(newData);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const link = document.getElementById("closeTraing1");
    let users = [];
    for (let i = 0; i < selectedUser.length; i++) {
      users.push(selectedUser[i].value);
    }
    const body = {
      company_id: companyId,
      title: title,
      target_audience: users,
      training_mode: targingMode,
      created_by: userId,
      status: status,
      budget: targingBudget,
      description: targingDesc,
      duration: trainingDuration,
      assinged_trainner: selectedTrainer,
      start_date: startDate,
      end_date: endDate,
    };

    TrainingServices.updateTraining(editId, body).then((res) => {
      link.click();
      getTrainings(companyId);
    });
  };

  useEffect(() => {
    getCompanyUsers(companyId);
    console.log("editId:", editId);
    if (editId !== "") {
      TrainingServices.viewTraining(editId).then((res) => {
        const data = res.data.user[0];
        let newData = [];
        for (let i = 0; i < data.target_audience.length; i++) {
          let obj = companyUserList.find(
            (els) => els.value === data.target_audience[i]
          );
          newData.push(obj);
        }
        setSelectedUser(newData);
        setStatus(data.status);
        setTargingDesc(data.description);
        setTargingBudget(data.budget);
        setTargingMode(data.training_mode);
        setTitle(data.title);
        setTrainingDuration(data.duration);
        setSelectedTrainer(data.assinged_trainner);
        setStartDate(moment(data.start_date).format("YYYY-MM-DD"));
        setEndDate(moment(data.end_date).format("YYYY-MM-DD"));
      });
    }
  }, [editId]);
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id={id}
        aria-labelledby={labelledby}
        style={{ width: "600px" }}
      >
        <div className="offcanvas-header border-bottom">
          <h5 id={labelledby}>View Training</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id="closeTraing1"
          />
        </div>
        <div className="offcanvas-body">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
              <div className="row" style={{ paddingRight: "2rem" }}>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Traning Title<span className="alert_star">*</span>
                      </label>
                    </div>

                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Traning Duration<span className="alert_star">*</span>
                      </label>
                    </div>

                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        name="trainingDuration"
                        value={trainingDuration}
                        onChange={(e) => setTrainingDuration(e.target.value)}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Traning Mode<span className="alert_star">*</span>
                      </label>
                    </div>

                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        name="lead_owner"
                        id="basicSelect"
                        value={targingMode}
                        onChange={(e) => setTargingMode(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        <option value="instructor-led">Instructor Led</option>
                        <option value="video">Video</option>
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Select Employee's<span className="alert_star">*</span>
                      </label>
                    </div>

                    <div className="col-sm-9">
                      <Select
                        options={companyUserList}
                        value={selectedUser}
                        isMulti
                        onChange={(e) => setSelectedUser(e)}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Traning Budget
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="targingBudget"
                        value={targingBudget}
                        onChange={(e) => setTargingBudget(e.target.value)}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Traning Status
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        name="lead_owner"
                        id="basicSelect"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        <option value="pending">Pending</option>
                        <option value="acknowledged">Acknowledged</option>
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Description<span className="alert_star">*</span>
                      </label>
                    </div>

                    <div className="col-sm-9">
                      <textarea
                        className="form-control selectset custominput"
                        name="targingDesc"
                        id="basicSelect"
                        value={targingDesc}
                        onChange={(e) => setTargingDesc(e.target.value)}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Assigne Trainer
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        name="selectedTrainer"
                        id="basicSelect"
                        value={selectedTrainer}
                        onChange={(e) => setSelectedTrainer(e.target.value)}
                      >
                        {allCompanyUser?.map((esa) => (
                          <option value={esa.id}>
                            {esa.first_name} {esa.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Start Date
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <input
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control"
                        type="date"
                        name="startdate"
                      />
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        End Date
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <input
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control"
                        type="date"
                        name="enddate"
                      />
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-4">
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ViewTraining;
