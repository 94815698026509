import requests from "./httpService";

const LectureService = {
  showAllLectures(id, body) {
    return requests.get(`/trainings/lectures/${id}`, body);
  },
  viewLectures(id, body) {
    return requests.get(`/trainings/lectureDetails/${id}`, body);
  },
  addLectures(id, body) {
    return requests.post(`/trainings/addLecture/${id}`, body);
  },
  updateLectures(id, body) {
    return requests.post(`/trainings/lectureUpdate/${id}`, body);
  },
};

export default LectureService;
