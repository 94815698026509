import React, { useState } from "react";
import PerformaCheck from "./PerformaCheck";

const TalentPool = ({ applicatns }) => {
  const [viewData, setViewData] = useState([]);

  const handelView = (e, value) => {
    e.preventDefault();
    setViewData(value);
  };

  return (
    <>
      <div className="col-lg-12">
        <PerformaCheck
          viewData={viewData}
          offcanvasid={"offcanvasRightCreate1"}
          labelledby={"offcanvasRightLabel1"}
        />
        <div className="card">
          <div className="card-body">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body p-0">
                  <table className="table mb-0 table-striped">
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>Applicant's Name</th>
                        <th>Apply for</th>
                        <th>Applied Location</th>
                        <th>Email</th>
                        <th>CV</th>
                        <th>Check Performa</th>
                      </tr>
                    </thead>
                    <tbody>
                      {applicatns.map((es) => (
                        <>
                          {es.applicants
                            .filter((es) => es.status === "talentpool")
                            .map((ess) => (
                              <tr>
                                <th scope="row">
                                  {ess.first_name}
                                  {ess.first_name}
                                </th>
                                <td className="tableset">{es.title}</td>
                                <td className="tableset">{ess.location}</td>
                                <td className="tableset">{ess.email}</td>

                                <td className="tableset">
                                  <a
                                    target="_blank"
                                    href={`${process.env.REACT_APP_CV_PATH}/${ess.cv}`}
                                  >
                                    <i className="uil-eye iconscolor" />
                                  </a>
                                </td>
                                <td className="tableset">
                                  <button
                                    onClick={(e) => handelView(e, ess.performa)}
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRightCreate1"
                                    aria-controls="offcanvasRight1"
                                    className="btn btnclr"
                                  >
                                    Performa Check
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentPool;
