import requests from "./httpService";

const TrainingServices = {
  getallTraining(id, body) {
    return requests.get(`/trainings/all?company_id=${id}`, body);
  },
  addTraining(body) {
    return requests.post(`/trainings/create`, body);
  },
  updateTrainingStatus(id, body) {
    return requests.post(`/trainings/statusupdate/${id}`, body);
  },
  viewTraining(id, body) {
    return requests.get(`/trainings/${id}`, body);
  },
  updateTraining(id, body) {
    return requests.post(`/trainings/update/${id}`, body);
  },
  trainerView(uid, cid, body) {
    return requests.get(`/trainings/trainerView/${uid}?company_id=${cid}`, body);
  },
};

export default TrainingServices;
