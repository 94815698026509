import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import CompanyService from "../../../Services/CompanyService";
import TrainingServices from "../../../Services/TrainingServices";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const UserTrainer = () => {
  
  const navigate = useNavigate();
  const companyId = DataParser.CompanyId();
  const userIUd = DataParser.UserId();
  const [trainingList, setTraininglist] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);
  const [editId, setEditId] = useState("");

  const getTrainings = (uid, cid) => {
    TrainingServices.trainerView(uid, cid).then((res) => {
      const data = res.data.Trainings;
      setTraininglist(data);
      console.log("res:", res);
    });
  };

  const getCompanyUsers = () => {
    CompanyService.getComapnyUser(companyId).then((res) => {
      const data = res.data.user;
      setCompanyUserList(data);
    });
  };

  useEffect(() => {
    
    getTrainings(userIUd, companyId);
    getCompanyUsers();
  }, []);

  const leactureRoute = (e, tid) => {
    e.preventDefault();
    navigate(`/${DataParser.CompanyIdentifierRole()}/lecture`, {
      state: { data: tid },
    });
  };

  return (
    <>
      <Helmet>
        <title>Traning - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Tranings</h4>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <table id="dataEable" className="table mb-0 table-striped">
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>Title</th>
                        <th>Training Mode</th>
                        <th>Duartion</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trainingList.map((el, index) => (
                        <tr
                          role="row"
                          className={index % 2 === 0 ? "odd" : "even"}
                        >
                          <td className="tableset">{el.title}</td>
                          <td className="tableset">{el.training_mode}</td>
                          <td className="tableset">{el.duration} Days</td>
                          <td className="tableset">{el.status}</td>
                          <td className="tableset">
                            <Link
                              onClick={(e) => leactureRoute(e, el.id)}
                              to="#"
                            >
                              <button> Lectures </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTrainer;
