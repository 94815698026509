import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import AddRepater from "./Repeater/AddRepater";
import DataParser from "../../../utlits/Dataparser";
import VacancyService from "../../../Services/VacancyService";
import DepartmentServices from "../../../Services/DepartmentServices";

const CreateVacancy = (props) => {
  const navigate = useNavigate();
  const cid = DataParser.CompanyId();
  const [vacTitle, setVacTitle] = useState("");
  const [availableSeats, setAvaibleSeats] = useState("");
  const [location, setLocation] = useState("");
  const [requiredExp, setRequiredExp] = useState("");
  const [reqEducation, setRequireEducation] = useState("");
  const [lowerAge, setLowerAge] = useState("");
  const [uperAge, setuperAge] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [gender, setGender] = useState("");
  const [jobType, setJobType] = useState("");
  const [departmeid, setDepartmentId] = useState("");
  const [deplist, setDeplist] = useState([]);

  const [inputFields, setInputFields] = useState([]);
  const [repeater, setRepeater] = useState([]);

  const getDepartments = () => {
    // const companyId = DataParser.CompanyId();
    const comapnyId = DataParser.CompanyId();
    DepartmentServices.getCompanyDepartment(comapnyId).then((res) => {
      const dataset = res.data.departments;
      setDeplist(dataset);
    });
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const [gendersList] = useState([
    { text: "Male", value: "1" },
    { text: "Female", value: "2" },
    { text: "Both", value: "3" },
  ]);
  const [jobTypeList] = useState([
    { text: "Remote job", value: "1" },
    { text: "In Office job", value: "2" },
  ]);

  const handelSubmitVac = (e) => {
    e.preventDefault();
    let bData = {
      company_id: cid,
      title: vacTitle,
      location: location,
      required_education: reqEducation,
      start_date: startDate,
      expiry_date: endDate,
      gender: gender,
      avalaible_seats: availableSeats,
      experience_required: requiredExp,
      lower_age_limt: lowerAge,
      upper_age_limt: uperAge,
      description: jobDescription,
      job_type: jobType,
      questionanir: repeater,
      department_id: departmeid,
    };

    console.log(bData);
    VacancyService.createVacancy(bData).then((res) => {
      navigate(`/${DataParser.CompanyIdentifierRole()}/hire`);
    });
  };

  return (
    <>
      <Helmet>
        <title>Create Vacancy - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Create Vacancy</h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* Nav tabs */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom nav-justified"
                      role="tablist"
                    >
                      <li className="nav-item custome">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#brief"
                          role="tab"
                          aria-selected="true"
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home" />
                          </span>
                          <span className="d-none d-sm-block">
                            Vacancy Details
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#leavepolicy"
                          role="tab"
                          aria-selected="false"
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user" />
                          </span>
                          <span className="d-none d-sm-block">
                            Questionnaire
                          </span>
                        </a>
                      </li>
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content p-3 text-muted">
                      <div
                        className="tab-pane active"
                        id="brief"
                        role="tabpanel"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom01"
                              >
                                Vacancy Title
                              </label>
                              <input
                                type="text"
                                value={vacTitle}
                                onChange={(e) => setVacTitle(e.target.value)}
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Vacancy Title"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom02"
                              >
                                Available Seats
                              </label>
                              <input
                                type="text"
                                value={availableSeats}
                                onChange={(e) =>
                                  setAvaibleSeats(e.target.value)
                                }
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Available Seats"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom03"
                              >
                                Location
                              </label>
                              <input
                                type="text"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                className="form-control"
                                id="validationCustom03"
                                placeholder="Available Seats"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom04"
                              >
                                Required Experience (Years)
                              </label>
                              <input
                                type="text"
                                value={requiredExp}
                                onChange={(e) => setRequiredExp(e.target.value)}
                                className="form-control"
                                id="validationCustom04"
                                placeholder="Required Experience"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom05"
                              >
                                Required Education
                              </label>
                              <input
                                type="text"
                                value={reqEducation}
                                onChange={(e) =>
                                  setRequireEducation(e.target.value)
                                }
                                className="form-control"
                                id="validationCustom05"
                                placeholder="Required Education"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-4">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom07"
                                  >
                                    Lower Age limit
                                  </label>
                                  <input
                                    value={lowerAge}
                                    onChange={(e) =>
                                      setLowerAge(e.target.value)
                                    }
                                    type="text"
                                    className="form-control"
                                    id="validationCustom07"
                                    placeholder="Lower Age limit"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom09"
                                  >
                                    Upper Age limit
                                  </label>
                                  <input
                                    type="text"
                                    value={uperAge}
                                    onChange={(e) => setuperAge(e.target.value)}
                                    className="form-control"
                                    id="validationCustom09"
                                    placeholder="Upper Age limit"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom09"
                                  >
                                    Department
                                  </label>
                                  <select
                                    onChange={(e) =>
                                      setDepartmentId(e.target.value)
                                    }
                                    className="form-control"
                                  >
                                    {deplist.map((els) => (
                                      <option value={els.id}>{els.name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom07"
                                  >
                                    Start Date
                                  </label>
                                  <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) =>
                                      setStartDate(e.target.value)
                                    }
                                    className="form-control"
                                    id="validationCustom07"
                                    placeholder="Start Date"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom09"
                                  >
                                    Job Expiry Date
                                  </label>
                                  <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    className="form-control"
                                    id="validationCustom09"
                                    placeholder="Job Expiry Date"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom10"
                              >
                                Job Description
                              </label>
                              <textarea
                                value={jobDescription}
                                onChange={(e) =>
                                  setJobDescription(e.target.value)
                                }
                                className="form-control"
                                id="validationCustom10"
                                placeholder="Job Description"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="font_12 font-weight-bold text-primary-d">
                              Required Gender
                            </label>
                            <br />
                            <div
                              className="form-check form-check-inline font_12  mt-2"
                              style={{ display: "inline-grid" }}
                            >
                              {gendersList.map((es) => (
                                <>
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input vacancy_type"
                                      type="radio"
                                      name="gender"
                                      value={es.value}
                                      checked={gender === es.value}
                                      onChange={(e) =>
                                        setGender(e.target.value)
                                      }
                                    />
                                    {es.text}
                                  </label>
                                </>
                              ))}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="form-check form-check-inline font_12  mt-2"
                              style={{ display: "inline-grid" }}
                            >
                              {jobTypeList.map((ess) => (
                                <>
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input vacancy_type"
                                      type="radio"
                                      name="jpbstatys"
                                      value={ess.value}
                                      checked={jobType === ess.value}
                                      onChange={(e) =>
                                        setJobType(e.target.value)
                                      }
                                    />
                                    {ess.text}
                                  </label>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col text-right">
                          {/* <Link
                            onClick={(e) => goNextTab(e)}
                            className="btn btn-color continue"
                            to="#"
                          >
                            Next
                          </Link> */}
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="leavepolicy"
                        role="tabpanel"
                      >
                        <div className="row">
                          <div className="col-12">
                            <AddRepater
                              repeater={repeater}
                              setRepeater={setRepeater}
                            />
                          </div>

                          <div className="col-12 text-right">
                            <Link
                              onClick={(e) => handelSubmitVac(e)}
                              className="btn btn-color continue"
                              to="#"
                            >
                              Submit
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateVacancy;
