import requests from "./httpService";

const InstructorService = {
    getallInstructor(id, body) {
        return requests.get(`/trainings/instructors?company_id=${id}`, body);
    },
    addInstructor(body) {
        return requests.post(`/trainings/addInstructor`, body);
    },
    viewDetailInstructor(id, body) {
        return requests.get(`/trainings/instructor/${id}`, body);
    },
    updateInstructor(id, body) {
        return requests.post(`/trainings/instructorUpdate/${id}`, body);
    },
    asigneInstructor(id, body) {
        return requests.post(`/trainings/assigned-trainner/${id}`, body);
    },
};

export default InstructorService;
