import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Sidebar from "../../Layouts/Sidebar";
import VacancyService from "../../../Services/VacancyService";
import Applica from "./ApplicantsListing/Applicants";
import Accepted from "./ApplicantsListing/Accepted";
import Interviewed from "./ApplicantsListing/Interviewed";
import Rejected from "./ApplicantsListing/Rejected";
import ShortListed from "./ApplicantsListing/ShortListed";
import TalentPool from "./ApplicantsListing/TalentPool";

const Applicants = () => {
  const location = useLocation();

  const [applicatns, setApplicants] = useState([]);
  const [selectedList, setSelectedList] = useState("Applicants");
  const [eidss, setEidss] = useState(null);
  useEffect(() => {
    if (location?.state?.jobid) {
      setEidss(location?.state?.jobid);
      getData(location?.state?.jobid);
    }
  }, []);

  const getData = (eid) => {
    VacancyService.vacancyApplicantsById(eid).then((res) => {
      const daata = res.data.applicants;
      console.log(daata[0].applicants);
      setApplicants(daata);
    });
  };

  return (
    <>
      <Helmet>
        <title>Hiring - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Applicants</h4>
                </div>
              </div>
              <div className="col-12 icon_div">
                <div className="row">
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#7571F9" }}
                      >
                        {applicatns &&
                          applicatns[0]?.applicants.filter(
                            (es) => es.status === "applied"
                          ).length}
                      </legend>
                      <Link
                        to="#"
                        onClick={(e) => setSelectedList("Applicants")}
                        className="app_btn open_pending_app"
                      >
                        <img
                          className="recruit_img"
                          src="/assets/images/applicants.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span id="appli" className="font_12 font-weight-bold">
                      Applicants
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#F8C038" }}
                      >
                        {applicatns &&
                          applicatns[0]?.applicants.filter(
                            (es) => es.status === "shortlisted"
                          ).length}
                      </legend>
                      <Link
                        to="#"
                        onClick={(e) => setSelectedList("Shortlisted")}
                        className="app_btn open_shortlisted_app"
                      >
                        <img
                          className="recruit_img"
                          src="/assets/images/shortlisted.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">
                      Shortlisted
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#2ABFCC" }}
                      >
                        {applicatns &&
                          applicatns[0]?.applicants.filter(
                            (es) => es.status === "interview"
                          ).length}
                      </legend>
                      <Link
                        to="#"
                        onClick={(e) => setSelectedList("Interviewed")}
                        className="app_btn open_interviewed_app"
                      >
                        <img
                          className="recruit_img"
                          src="/assets/images/interviewed.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">
                      Interviewed
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#0ACF97" }}
                      >
                        {applicatns &&
                          applicatns[0]?.applicants.filter(
                            (es) => es.status === "accepted"
                          ).length}
                      </legend>
                      <Link
                        to="#"
                        onClick={(e) => setSelectedList("Accepted")}
                        className="app_btn open_accepted_app"
                      >
                        <img
                          className="recruit_img"
                          src="/assets/images/accepted.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">Accepted</span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#FF5E5E" }}
                      >
                        {applicatns &&
                          applicatns[0]?.applicants.filter(
                            (es) => es.status === "rejected"
                          ).length}
                      </legend>
                      <Link
                        to="#"
                        onClick={(e) => setSelectedList("Rejected")}
                        className="app_btn open_rejected_app"
                      >
                        <img
                          className="recruit_img"
                          src="/assets/images/rejected.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">Rejected</span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#97CEF8" }}
                      >
                        {applicatns &&
                          applicatns[0]?.applicants.filter(
                            (es) => es.status === "talentpool"
                          ).length}
                      </legend>
                      <Link
                        to="#"
                        onClick={(e) => setSelectedList("Talent Pool")}
                      >
                        <img
                          className="recruit_img"
                          src="/assets/images/talent-pool.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">
                      Talent Pool
                    </span>
                  </div>
                </div>
              </div>
              {selectedList === "Applicants" ? (
                <Applica
                  applicatns={applicatns}
                  getData={getData}
                  eidss={eidss}
                />
              ) : null}
              {selectedList === "Shortlisted" ? (
                <ShortListed
                  applicatns={applicatns}
                  getData={getData}
                  eidss={eidss}
                />
              ) : null}
              {selectedList === "Interviewed" ? (
                <Interviewed
                  applicatns={applicatns}
                  getData={getData}
                  eidss={eidss}
                />
              ) : null}
              {selectedList === "Accepted" ? (
                <Accepted applicatns={applicatns} />
              ) : null}
              {selectedList === "Rejected" ? (
                <Rejected applicatns={applicatns} />
              ) : null}
              {selectedList === "Talent Pool" ? (
                <TalentPool applicatns={applicatns} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Applicants;
