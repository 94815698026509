import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CompanyService from "../../../Services/CompanyService";
import InstructorService from "../../../Services/InstructorService";
import TrainingServices from "../../../Services/TrainingServices";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import TrainingStatusFiled from "../../Reuseable/TrainingStatusFiled";
import AddTraining from "./AddTraining";
import ViewTraining from "./ViewTraining";

const Traning = () => {
  const companyId = DataParser.CompanyId();
  const [trainingList, setTraininglist] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);
  const [editId, setEditId] = useState("");

  const getTrainings = (cid) => {
    TrainingServices.getallTraining(cid).then((res) => {
      const data = res.data.Trainings;
      setTraininglist(data);
      console.log("res:", res);
    });
  };

  const getCompanyUsers = () => {
    CompanyService.getComapnyUser(companyId).then((res) => {
      const data = res.data.user;
      setCompanyUserList(data);
    });
  };

  useEffect(() => {
    getTrainings(companyId);
    getCompanyUsers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Traning - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <AddTraining
              id={"offcanvasRightTraining"}
              labelledby={"offcanvasRightTrainingLabel"}
              getTrainings={getTrainings}
            />
            <ViewTraining
              editId={editId}
              id={"offcanvasRightEditTraining"}
              labelledby={"offcanvasRightEditTrainingLabel"}
              getTrainings={getTrainings}
            />
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Traning</h4>
                  <div className="page-title-right">
                    <Link to="#">
                      <span
                        type="button"
                        className="btn btnsss waves-effect waves-light"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRightTraining"
                        aria-controls="offcanvasRightTraining"
                      >
                        Add Traning
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <table id="dataEable" className="table mb-0 table-striped">
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>Title</th>
                        <th>Training Mode</th>
                        <th>Training Users</th>
                        <th>Training Instuctor</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trainingList.map((el, index) => (
                        <tr
                          role="row"
                          className={index % 2 === 0 ? "odd" : "even"}
                        >
                          <td className="tableset">{el.title}</td>
                          <td className="tableset">{el.training_mode}</td>
                          <td className="tableset">
                            {el.target_audience.map((elss) => (
                              <span
                                style={{ marginRight: "5px" }}
                                className="btn btn-success waves-effect waves-light btn-sm"
                              >
                                {
                                  companyUserList?.find(
                                    (ase) => ase.id === elss
                                  )?.first_name
                                }
                                &nbsp;
                                {
                                  companyUserList?.find(
                                    (ase) => ase.id === elss
                                  )?.last_name
                                }
                              </span>
                            ))}
                          </td>
                          <td className="tableset">
                            <span
                              style={{ marginRight: "5px" }}
                              className="btn btn-success waves-effect waves-light btn-sm"
                            >
                              {
                                companyUserList?.find(
                                  (ase) => ase.id === el.assinged_trainner
                                )?.first_name
                              }
                              &nbsp;
                              {
                                companyUserList?.find(
                                  (ase) => ase.id === el.assinged_trainner
                                )?.last_name
                              }
                            </span>
                          </td>
                          <td className="tableset">
                            <TrainingStatusFiled eid={el.id} item={el.status} />
                          </td>
                          <td className="tableset">
                            <Link
                              onClick={(e) => setEditId(el.id)}
                              to="#"
                              data-bs-toggle="offcanvas"
                              data-bs-target={`#offcanvasRightEditTraining`}
                              aria-controls={`offcanvasRightEditTraining`}
                            >
                              <i className="uil-pen iconscolor" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Traning;
