import requests from "./httpService";

const VacancyService = {
  createVacancy(body) {
    return requests.post(`/hire/create`, body);
  },
  getAllVacancies(id,depid, body) {
    return requests.get(`/hire/all?company_id=${id}&department_id=${depid}`, body);
  },
  editVacancies(id, body) {
    return requests.get(`/hire/${id}`, body);
  },
  updateVacancies(id, body) {
    return requests.post(`/hire/update/${id}`, body);
  },
  applyVacancies(id, body) {
    return requests.post(`/hire/userApplication/${id}`, body);
  },
  vacancyApplicantsById(id, body) {
    return requests.post(`/hire/jobApplicant/${id}`, body);
  },
  vacancyApplicants(compid, body) {
    return requests.get(`/hire/alljobApplicant?company_id=${compid}`, body);
  },
  updateVacancyApplicantsStatus(id, status, body) {
    return requests.post(`/hire/statusupdate/${id}?status=${status}`, body);
  },
  VacancyApplicantsPerforma(id, body) {
    return requests.post(`/hire/interviewStatus/${id}`, body);
  },

  talentPoolAllVacancy(body) {
    return requests.get(`/hire/talentPool`, body);
  },
};


export default VacancyService;
