import axios from "axios";
import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import VacancyService from "../../../Services/VacancyService";

const CreateCareer = (props) => {
  const { offcanvasid, labelledby, selectedItem, questionModified } = props;

  const checkAuth =
    secureLocalStorage.getItem("adminInfo") === undefined
      ? ""
      : `${JSON.parse(secureLocalStorage.getItem("adminInfo")).authToken}`;
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [emails, setEmails] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [lastEducation, setLastEducation] = useState("");
  const [questions, setQuestions] = useState([]);
  const [file, setFile] = useState(null);

  const handleRepeatingFields = (index, event) => {
    let data = [...questionModified];
    console.log("data:", data);
    console.log("data:", event.target.name);
    data[index]["selectedValue"] = event.target.value;
    setQuestions(data);
  };

  const handelApply = (e) => {
    e.preventDefault();
    let bdata = {
      firstname: fname,
      lastname: lname,
      location: currentLocation,
      education: lastEducation,
      questionModified: questions,
    };
    console.log("submit:", bdata);

    var datas = new FormData();
    datas.append("job_id", selectedItem.id);
    datas.append("first_name", fname);
    datas.append("last_name", lname);
    datas.append("email", emails);
    datas.append("location", currentLocation);
    datas.append("education", lastEducation);
    datas.append("questionanir", JSON.stringify(questions));
    datas.append("file", file);

    const link = document.getElementById("closeButton");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}hire/userApplication/${selectedItem.id}`,
        datas,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authToken: `${checkAuth}`,
          },
        }
      )
      .then((res) => {
        console.log("Res:", res);
        link.click();
        setFName("");
        setLName("");
        setEmails("");
        setCurrentLocation("");
        setLastEducation("");
        setQuestions("");
        setFile("");
      });
  };
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id={offcanvasid}
        aria-labelledby={labelledby}
        style={{ width: "650px" }}
      >
        <div className="offcanvas-header border-bottom">
          <h5 id={labelledby}>Job Application</h5>
          <button
            type="button"
            id="closeButton"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <form>
            <div
              style={{ paddingRight: "2rem" }}
              className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy"
            >
              <div className="row">
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        First Name<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        value={fname}
                        onChange={(e) => setFName(e.target.value)}
                        type="text"
                        id="fname"
                        className="form-control mb-3"
                        placeholder="Enter your firstname*"
                        required
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Last Name<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        value={lname}
                        onChange={(e) => setLName(e.target.value)}
                        type="text"
                        id="lname"
                        className="form-control mb-3"
                        placeholder="Enter your lastname*"
                        required
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Email<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        value={emails}
                        onChange={(e) => setEmails(e.target.value)}
                        type="email"
                        id="email"
                        className="form-control mb-3"
                        placeholder="Enter your lastname*"
                        required
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Current Location<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        value={currentLocation}
                        onChange={(e) => setCurrentLocation(e.target.value)}
                        type="text"
                        id="location"
                        className="form-control mb-3"
                        placeholder="Enter your location*"
                        required
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Last Education<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        value={lastEducation}
                        onChange={(e) => setLastEducation(e.target.value)}
                        type="text"
                        id="hedu"
                        className="form-control mb-3"
                        placeholder="Enter your latest education*"
                        required
                      />
                    </div>
                  </fieldset>
                </div>
                {questionModified &&
                  questionModified.map((ess, index) => (
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect"
                          >
                            {ess.question}
                            <span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-6">
                          {ess.questionType === "Dropdown List" ? (
                            <select
                              name={ess.questionType}
                              onChange={(event) =>
                                handleRepeatingFields(index, event)
                              }
                              className="form-control"
                            >
                              {ess.fields.map((ea) => (
                                <option>{ea.targetname}</option>
                              ))}
                            </select>
                          ) : null}
                          {ess.questionType === "Radio Buttons" ? (
                            <>
                              <div
                                className="form-check form-check-inline font_12  mt-2 mb-2"
                                style={{ display: "inline-grid" }}
                              >
                                {ess.fields.map((el) => (
                                  <label className="form-check-label">
                                    <input
                                      onChange={(event) =>
                                        handleRepeatingFields(index, event)
                                      }
                                      className="form-check-input vacancy_type"
                                      type="radio"
                                      name={ess.questionType}
                                      defaultValue={1}
                                    />
                                    {el.targetname}
                                  </label>
                                ))}
                              </div>
                            </>
                          ) : null}
                          {ess.questionType === "textarea" ? (
                            <textarea
                              name={ess.questionType}
                              onChange={(event) =>
                                handleRepeatingFields(index, event)
                              }
                              className="form-control"
                              rows={3}
                            />
                          ) : null}
                          {ess.questionType === "input" ? (
                            <input
                              name={ess.questionType}
                              onChange={(event) =>
                                handleRepeatingFields(index, event)
                              }
                              type="text"
                              id="dynamic"
                              className="form-control mb-3"
                              placeholder=""
                              required
                            />
                          ) : null}
                        </div>
                      </fieldset>
                    </div>
                  ))}
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Upload CV<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        onChange={(e) => setFile(e.target.files[0])}
                        type="file"
                        id="myFile"
                        name="filename"
                        required
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-4">
                  <button
                    onClick={(e) => handelApply(e)}
                    type="submit"
                    className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                  >
                    Submits
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateCareer;
