import React, { useState } from "react";
import TrainingServices from "../../Services/TrainingServices";

const TrainingStatusFiled = ({ eid, item }) => {
  const [statusList, setStatusList] = useState(item);

  const handelStatusChange = (e) => {
    e.preventDefault();
    setStatusList(e.target.value);
    let body = { status: e.target.value };
    TrainingServices.updateTrainingStatus(eid, body).then((res) => {});
  };

  return (
    <>
      <select
        className="form-control sr selectset"
        value={statusList}
        onChange={(e) => handelStatusChange(e)}
      >
        <option value="">Please Select</option>
        <option value="pending">Pending</option>
        <option value="acknowledged">Acknowledged</option>
      </select>
    </>
  );
};

export default TrainingStatusFiled;
