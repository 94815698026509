import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CompanyService from "../../../Services/CompanyService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import LectureService from "../../../Services/LectureService";

const Lectures = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const companyId = DataParser.CompanyId();
  const userIUd = DataParser.UserId();
  const [lectureList, setLectureList] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);
  const [trainnigId, setTrainingId] = useState("");

  const getLectures = (uid) => {
    LectureService.showAllLectures(uid).then((res) => {
      console.log("res: ", res);
      const data = res.Lectures;
      setLectureList(data);
    });
  };

  const getCompanyUsers = () => {
    CompanyService.getComapnyUser(companyId).then((res) => {
      const data = res.data.user;
      setCompanyUserList(data);
    });
  };

  useEffect(() => {
    console.log("location: ", location);
    setTrainingId(location?.state?.data);
    getLectures(location?.state?.data);
    getCompanyUsers();
  }, []);

  const leactureAddRoute = (e) => {
    e.preventDefault();
    navigate(`/${DataParser.CompanyIdentifierRole()}/addlecture`, {
      state: { data: trainnigId },
    });
  };

  return (
    <>
      <Helmet>
        <title>Add Lecture - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Lecture</h4>
                  <div className="page-title-right">
                    <Link onClick={(e) => leactureAddRoute(e)} to="#">
                      <span
                        type="button"
                        className="btn btnsss waves-effect waves-light"
                      >
                        Add Lectures
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <table id="dataEable" className="table mb-0 table-striped">
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>Title</th>
                        <th>Description</th>
                        <th>Video Url</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lectureList.map((el, index) => (
                        <tr
                          role="row"
                          className={index % 2 === 0 ? "odd" : "even"}
                        >
                          <td className="tableset">{el.title}</td>
                          <td className="tableset">
                            {el.short_des === "" ? "No" : "Yes"}
                          </td>
                          <td className="tableset">
                            {el.youtubeLink === "" ? "No" : "Yes"}
                          </td>
                          <td className="tableset">
                            <Link
                              to={`/${DataParser.CompanyIdentifierRole()}/viewlecture/${
                                el.id
                              }`}
                              className="invoice-action-edit cursor-pointer"
                            >
                              <i className="uil-pen iconscolor" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lectures;
