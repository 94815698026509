import moment from "moment";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import Chart from "react-apexcharts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import shortid from "shortid";
import { DatePicker } from "antd";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AttendanceService from "../../../Services/AttendanceService";
import LeaveApplicationService from "../../../Services/LeaveApplicationService";
import LeaveEntitlementService from "../../../Services/LeaveEntitlementService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const AttendanceEmploye = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [apiUser, setApiUser] = useState(null);
  const [attendanceList, setAttendenceList] = useState([]);
  const [userEntitlementList, setUserEntitlementsList] = useState([]);
  const [userLeaves, setUserLeaves] = useState([]);
  const [userLeaveDates, setUserLeaveDates] = useState([]);
  const [userLeaveDatesWorkHours, setUserLeaveDatesWorkHours] = useState([]);
  const [workFromHome, setWorkFromHome] = useState(null);
  const [status, setStatus] = useState(null);
  const [isDisabled, setIsDisable] = useState(null);
  const [updatePunchIn, setUpdatePunchIn] = useState(null);
  const [updatePunchOut, setUpdatePunchOut] = useState(null);
  const [editId, setEditId] = useState(null);
  const [selectMonth, setSelectMonth] = useState("");
  const [selectYear, setSelectYear] = useState("");
  const compId = DataParser.CompanyId();
  const userid = DataParser.UserId();
  const currentDate = DataParser.getDateinFormat();
  const currentTime = DataParser.getCurrentTime();

  const [empName, setEmpName] = useState("");

  //Statistics and todays activity

  const [activityCheckin, setActivityCheckin] = useState(null);
  const [activityCheckout, setActivityCheckout] = useState(null);

  //csv link

  const headersCsv = [
    { label: "Date", key: "date" },
    { label: "Punch In", key: "timein" },
    { label: "Punch Out", key: "timeout" },
    { label: "Working Hours", key: "totalhours" },
  ];

  useEffect(() => {
    if (location.state !== null) {
      const stateData = location?.state?.data;
      const stateName = location.state?.name;
      getAttendenceById(compId, stateData);
      getUserEntitlements(stateData, compId);
      getUserLeaves(stateData, compId);
      setEmpName(stateName);
      setApiUser(stateData);
    } else {
      getAttendence();
      getUserEntitlements(userid, compId);
      getUserLeaves(userid, compId);

      const usernamee = DataParser.CompanyUserName();
      setEmpName(usernamee);
    }
    checkPunchIns();
  }, []);

  const getTotalSum = (arr) => {
    const filter = arr?.filter(
      (el) => el.totalhours !== "NaN" && el.totalhours !== undefined
    );
    var msgTotal = filter?.reduce(function (prev, cur) {
      return prev + Math.ceil(cur.totalhours);
    }, 0);
    return msgTotal;
  };

  const calculateOvertime = (arr) => {
    const filter = arr?.filter(
      (el) => el.totalhours !== "NaN" && el.totalhours !== undefined
    );
    const noDays = filter?.length;

    const totalWorkingHours = noDays * 9;

    const ActualWorkingHours = getTotalSum(arr);

    const overtime = ActualWorkingHours - totalWorkingHours;
    if (overtime <= 0) {
      return 0;
    } else {
      return overtime;
    }
  };

  const getAttendence = () => {
    setLoading(true);
    AttendanceService.getAttendanceNew(compId, userid).then((res) => {
      const Data = res?.data?.attandence[0]?.attandence;
      const WorkFromHome = res?.data?.attandence[0]?.wrokfrom_home;
      setWorkFromHome(WorkFromHome);
      setAttendenceList(Data);
      setLoading(false);
    });
  };

  const getAttendenceById = (compId, id) => {
    setLoading(true);
    AttendanceService.getAttendanceNew(compId, id).then((res) => {
      const Data = res?.data?.attandence[0]?.attandence;
      const WorkFromHome = res?.data?.attandence[0]?.wrokfrom_home;
      setWorkFromHome(WorkFromHome);
      setAttendenceList(Data);
      setLoading(false);
    });
  };

  const getUserEntitlements = (u, c) => {
    LeaveEntitlementService.userLeaveEntitlement(u, c).then((res) => {
      const Data = res.data.entitlement;
      setUserEntitlementsList(Data);
    });
  };

  const getUserLeaves = (u, c) => {
    LeaveApplicationService.userLeave(u, c).then((res) => {
      const data = res.data.userLeaves;
      setUserLeaves(data);
    });
  };

  const UpdateAttendacne = () => {
    let timeine = DataParser.convetTimeOld(updatePunchIn);
    let timeout = DataParser.convetTimeOld(updatePunchOut);
    const Body = {
      timeIn: timeine,
      timeOut: timeout,
    };
    AttendanceService.updateAttendance(editId, Body).then((res) => {
      getAttendence();
    });
  };

  const checkPunchIns = (e) => {
    if (location.state !== null) {
      const stateData = location?.state?.data;
      const Data = {
        company_id: compId,
        data: {
          empid: stateData,
          date: moment().format(),
        },
      };

      AttendanceService.attendanceCheckPunchinNew(Data).then((res) => {
        setStatus(res.data);
        setActivityCheckin(res.timein);
        setActivityCheckout(res.timeout);
        if (res.punchout) {
          setIsDisable(res.punchout);
        }
      });
    } else {
      const Data = {
        company_id: compId,
        data: { empid: userid, date: moment().format() },
      };

      AttendanceService.attendanceCheckPunchinNew(Data).then((res) => {
        setStatus(res.data);
        setActivityCheckin(res.timein);
        setActivityCheckout(res.timeout);
        if (res.punchout) {
          setIsDisable(res.punchout);
        }
      });
    }
  };

  const handelPunchIn = (e) => {
    e.preventDefault();
    if (location.state !== null) {
      const stateData = location?.state?.data;
      let Data = {
        company_id: compId,
        data: {
          empid: stateData,
          date: moment().format(),
          timeIn: currentTime,
          timeOut: "",
        },
      };
      AttendanceService.attendancePunchin(Data).then((res) => {
        setStatus(res.data);
        checkPunchIns();
      });
    } else {
      let Data = {
        company_id: compId,
        data: {
          empid: userid,
          date: moment().format(),
          timeIn: currentTime,
          timeOut: "",
        },
      };
      AttendanceService.attendancePunchin(Data).then((res) => {
        setStatus(res.data);
        checkPunchIns();
      });
    }
  };

  const handelPunchOut = (e) => {
    e.preventDefault();
    if (location.state !== null) {
      const stateData = location?.state?.data;
      let Data = {
        company_id: compId,
        data: {
          empid: stateData,
          date: moment().format(),
          timeOut: currentTime,
        },
      };
      AttendanceService.attendancePunchoutNew(Data).then((res) => {
        setStatus(res.data);
        checkPunchIns();
      });
    } else {
      let Data = {
        company_id: compId,
        data: {
          empid: userid,
          date: moment(currentDate),
          timeOut: currentTime,
        },
      };
      AttendanceService.attendancePunchoutNew(Data).then((res) => {
        setStatus(res.data);
        checkPunchIns();
      });
    }
  };

  const handelUpdateAttendance = (e) => {
    e.preventDefault();
    var link = document.getElementById("createAttendanceModal");
    link.click();
    UpdateAttendacne();
  };

  const handelEdit = (e, item) => {
    console.log("itemalso:", item);
    let timein = DataParser.convertTime(item.timein);
    let timeout = DataParser.convertTime(item.timeout);
    setUpdatePunchIn(timein);
    setUpdatePunchOut(timeout);
    // alert(item.id);
    setEditId(item.id);
  };

  const onCancel = (e) => {
    e.preventDefault();
    setUpdatePunchIn(null);
    setUpdatePunchOut(null);
  };

  const handelSearch = (e) => {
    e.preventDefault();
    if (location.state !== null) {
      const stateData = location?.state?.data;
      AttendanceService.getAttendancebySearchNew(
        compId,
        stateData,
        selectMonth,
        selectYear
      ).then((res) => {
        const Data = res?.data?.attandence[0]?.attandence;
        setAttendenceList(Data);
      });
    } else {
      AttendanceService.getAttendancebySearchNew(
        compId,
        userid,
        selectMonth,
        selectYear
      ).then((res) => {
        const Data = res?.data?.attandence[0]?.attandence;
        setAttendenceList(Data);
      });
    }
  };

  const GotoUser = (e, s) => {
    e.preventDefault();
    //alert(s)
    navigate(`/${DataParser.CompanyIdentifierRole()}/employe_leaves`, {
      state: { data: apiUser },
    });
  };

  //handel apex charts

  const series = [
    {
      name: "TotalHours", //will be displayed on the y-axis
      data: attendanceList?.map((es) =>
        es.totalhours === "NaN" ? "0" : es.totalhours
      ),
    },
  ];

  const options = {
    colors: ["#00b5b8"],
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        tools: {
          download: false, // <== line to add
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: attendanceList?.map((es) =>
        moment(es.date).format("DD/MM/YYYY")
      ), //will be displayed on the x-asis
    },
  };

  const onChange = (date, dateString) => {
    console.log(dateString);
    let year = dateString.split("-")[0];
    let month = dateString.split("-")[1];
    setSelectMonth(month);
    setSelectYear(year);
  };
  return (
    <>
      <Helmet>
        <title>Attendance - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Attendance - {empName}</h4>
                </div>
              </div>

              <div className="container-fluid">
                <div className="row w-100">
                  <div className="col-md-4">
                    {workFromHome ||
                    DataParser.userRoleTitle() === "HR" ||
                    DataParser.userRoleTitle() === "Company Admin" ||
                    DataParser.userRoleTitle() === "Sub Admin" ? (
                      <>
                        <div
                          style={{ border: "solid 1px #0f223a1f" }}
                          className="card punch-status"
                        >
                          <div className="card-body">
                            <h5 className="card-title">
                              Timesheet {""}
                              <small className="text-muted">
                                {moment().format("DD MMMM YYYY")}
                              </small>
                            </h5>
                            <div className="punch-det">
                              <h6>Punch In at</h6>
                              <p>
                                {moment().format("LL")} {activityCheckin}
                              </p>
                            </div>
                            <div className="punch-info">
                              <div
                                className="punch-hours"
                                style={{
                                  textAlign: "center",
                                  position: "relative",
                                }}
                              >
                                <span
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    left: 0,
                                    top: "34%",
                                  }}
                                >
                                  {activityCheckin === "" ? (
                                    <>
                                      {" "}
                                      {DataParser.calculateTimeDifference1(
                                        0,
                                        0
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {isDisabled ? (
                                        <>
                                          {DataParser.calculateTimeDifference1(
                                            activityCheckin,
                                            activityCheckout
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {DataParser.calculateTimeDifference1(
                                            activityCheckin,
                                            moment().format("LT")
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                  /hrs
                                </span>
                              </div>
                            </div>
                            {workFromHome ||
                            DataParser.userRoleTitle() === "HR" ? (
                              <>
                                <div className="punch-btn-section">
                                  {status ? (
                                    <>
                                      {isDisabled ? (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn-color punch-btn punch-btn-disabled"
                                            disabled={isDisabled}
                                          >
                                            Punch Out
                                          </button>{" "}
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn-color punch-btn"
                                            onClick={(e) => handelPunchOut(e)}
                                          >
                                            Punch Out
                                          </button>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-primary punch-btn"
                                        onClick={(e) => handelPunchIn(e)}
                                      >
                                        Punch In
                                      </button>
                                    </>
                                  )}
                                </div>
                              </>
                            ) : null}

                            <div className="statistics">
                              <div className="row">
                                <div className="col-md-6 col-6 text-center">
                                  <div className="stats-box">
                                    <p>Break</p>
                                    <h6>1 hr</h6>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6 text-center">
                                  <div className="stats-box">
                                    <p>Overtime</p>
                                    <h6>
                                      {DataParser.calculateTimeDifference(
                                        activityCheckin,
                                        moment().format("LT")
                                      ) -
                                        9 >
                                      0
                                        ? DataParser.calculateTimeDifference(
                                            activityCheckin,
                                            moment().format("LT")
                                          ) - 9
                                        : "0 "}
                                      hrs
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        <div>
                          <img
                            className="img-fluid"
                            src="/assets/images/schedule.webp"
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="card att-statistics">
                      <div
                        style={{ border: "solid 1px #0f223a1f" }}
                        className="card-body"
                      >
                        <h5 className="card-title">Working Hours Statistic</h5>
                        <div className="stats-list">
                          <div className="stats-info">
                            <p className="d-flex justify-content-between">
                              Today
                              <strong>
                                {activityCheckin === "" ? (
                                  "0"
                                ) : (
                                  <>
                                    {DataParser.calculateTimeDifference(
                                      activityCheckin,
                                      activityCheckout
                                    )}
                                  </>
                                )}
                                <small>/ 9 hrs</small>
                              </strong>
                            </p>
                            <div className="progress">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{
                                  width: `${DataParser.calculateTimePercentage(
                                    activityCheckin,
                                    9
                                  )}%`,
                                }}
                                aria-valuenow={31}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>

                          <div className="stats-info">
                            <p className="d-flex justify-content-between">
                              This Month
                              <strong>
                                {getTotalSum(attendanceList)}
                                <small>/ 210 hrs</small>
                              </strong>
                            </p>
                            <div className="progress">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{
                                  width: `${DataParser.calculateTimePercentage(
                                    getTotalSum(attendanceList),
                                    210
                                  )}%`,
                                }}
                                aria-valuenow={62}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <div className="stats-info">
                            <p className="d-flex justify-content-between">
                              Remaining
                              <strong>
                                {210 - getTotalSum(attendanceList)}
                                <small>/ 210 hrs</small>
                              </strong>
                            </p>
                            <div className="progress">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{
                                  width: `${DataParser.calculateTimePercentage(
                                    210 - getTotalSum(attendanceList),
                                    210
                                  )}%`,
                                }}
                                aria-valuenow={62}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <div className="stats-info">
                            <p className="d-flex justify-content-between">
                              Overtime
                              <strong>
                                {calculateOvertime(attendanceList)} hrs
                              </strong>
                            </p>
                            <div className="progress">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{
                                  width: `${DataParser.calculateTimePercentage(
                                    DataParser.calculateTimeDifference(
                                      getTotalSum(attendanceList),
                                      DataParser.calculateTimeDifference(
                                        activityCheckin,
                                        activityCheckout
                                      )
                                    ),
                                    5
                                  )}%`,
                                }}
                                aria-valuenow={22}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="col-md-12">
                      <div className="col">
                        <div className="card recent-activity">
                          <div
                            className="card-body"
                            style={{ border: "solid 1px #0f223a1f" }}
                          >
                            <h5 className="card-title">Working Hours</h5>
                            <Chart
                              options={options}
                              type="area"
                              series={series}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col">
                        <div className="card recent-activity">
                          <div className="card-body">
                            <h5 className="card-title">Recent Activities</h5>
                            <ul className="res-activity-list">
                              <li>
                                <p className="mb-0">Punch In at</p>
                                <p className="res-activity-time">
                                  <i className="fa fa-clock-o" />
                                  {activityCheckin}
                                </p>
                              </li>
                              <li>
                                <p className="mb-0">Punch Out at</p>
                                <p className="res-activity-time">
                                  <i className="fa fa-clock-o" />
                                  {activityCheckout}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col">
                        <div className="card recent-activity">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <h5 className="card-title">Leave Updates</h5>
                              <Link to="#" onClick={(e) => GotoUser(e)}>
                                <i className="uil-eye iconscolor" />
                              </Link>
                            </div>
                            <div className="row">
                              {userEntitlementList.map((s) => (
                                <div
                                  className="col-md-4 stats-info-second card"
                                  key={shortid.generate()}
                                >
                                  <p>
                                    {s.leaveType}
                                    <br /> {s.used}/{s.total}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card recent-activity">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <h5 className="card-title">
                                Paid / Unpaid Leaves
                              </h5>
                              <Link
                                to={`/${DataParser.CompanyIdentifierRole()}/employee_leaveuser`}
                              >
                                <i className="uil-eye iconscolor" />
                              </Link>
                            </div>
                            <div className="row">
                              <div className="col-md-4 stats-info-second card">
                                <p>
                                  Paid Leaves <br />{" "}
                                  {userLeaves.filter((s) => s.typ === 1).length}
                                </p>
                              </div>
                              <div
                                style={{ marginLeft: "10px" }}
                                className="col-md-4 stats-info-second card"
                              >
                                <p>
                                  unPaid Leaves <br />{" "}
                                  {userLeaves.filter((s) => s.typ === 0).length}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-sm-6 col-md-12 d-flex justify-content-end">
                  <DatePicker
                    className="pr-2 "
                    onChange={onChange}
                    picker="month"
                    style={{ marginRight: "10px" }}
                  />
                  <div className="">
                    <Link
                      style={{
                        backgroundColor: "#00b5b8 !important",
                        borderColor: "#00b5b8 !important",
                        color: "white !important",
                        marginRight: "10px",
                      }}
                      onClick={(e) => handelSearch(e)}
                      to="#"
                      className="btn btn-color"
                    >
                      <i className="uil-search"></i> Search
                    </Link>
                    <CSVLink
                      className="btn btn-color"
                      data={attendanceList}
                      filename={"attendance.csv"}
                      headers={headersCsv}
                    >
                      <i className="uil-cloud-download"></i> Download me
                    </CSVLink>
                  </div>
                </div>
              </div>

              {/* this is for others */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div>
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Date</th>
                            <th>Punch In</th>
                            <th>Punch Out</th>
                            <th>Working Hours</th>
                            <th>Overtime</th>
                            {DataParser.userRoleTitle() === "HR" ||
                            DataParser.userRoleTitle() === "Company Admin" ||
                            DataParser.userRoleTitle() === "Sub Admin" ? (
                              <th>Action</th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {attendanceList &&
                                attendanceList.map((el) => (
                                  <tr key={shortid.generate()}>
                                    <td className="tableset">
                                      {moment(el.date).format("DD/MM/YYYY")}
                                    </td>
                                    <td className="tableset">
                                      {(el.leave === true) & (el.timein === "-")
                                        ? "Leave"
                                        : el.timein}
                                    </td>
                                    <td className="tableset">
                                      {(el.leave === true) &
                                      (el.timeout === "-")
                                        ? "Leave"
                                        : el.timeout}
                                    </td>
                                    <td className="tableset">
                                      {el.totalhours === "NaN"
                                        ? "-"
                                        : DataParser.calculateTimeDifference1(
                                            el.timein,
                                            el.timeout
                                          ) === "NaN:NaN"
                                        ? "Absent"
                                        : DataParser.calculateTimeDifference1(
                                            el.timein,
                                            el.timeout
                                          )}
                                    </td>
                                    <td className="tableset">
                                      {DataParser.calculateOvertimeAttendance(
                                        el.timein,
                                        el.timeout
                                      )}
                                      {/* {el.totalhours === "NaN" ? (
                                        "0"
                                      ) : (
                                        <>
                                          {el.totalhours - 9 === "NaN" ? (
                                            "0"
                                          ) : (
                                            <>
                                              {" "}
                                              {(el.totalhours - 9).toFixed(2)}
                                            </>
                                          )}
                                        </>
                                      )} */}
                                    </td>
                                    {DataParser.userRoleTitle() === "HR" ||
                                    DataParser.userRoleTitle() ===
                                      "Company Admin" ||
                                    DataParser.userRoleTitle() ===
                                      "Sub Admin" ? (
                                      <td className="tableset">
                                        <Link
                                          to="#"
                                          onClick={(e) => handelEdit(e, el)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#createAttendanceModal"
                                        >
                                          <i className="uil-pen iconscolor" />
                                        </Link>
                                      </td>
                                    ) : null}
                                  </tr>
                                ))}{" "}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Create modal content */}
      <div
        id="createAttendanceModal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <form onSubmit={(e) => handelUpdateAttendance(e)}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myModalLabel">
                  Update Attendance
                </h5>
                <button
                  onClick={(e) => onCancel(e)}
                  type="button"
                  className="btn-close"
                  id="createAttendanceModal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div
                    className="col-12 mb-3 d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-md-4">
                      <label
                        className="form-label"
                        style={{
                          paddingRight: "20px",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                        }}
                        htmlFor="pounchin"
                      >
                        Pounch In
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="time"
                        className="form-control"
                        step="3600000"
                        id="pounchin"
                        value={updatePunchIn}
                        onChange={(e) => setUpdatePunchIn(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-12 mb-3 d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-md-4">
                      <label
                        className="form-label"
                        style={{
                          paddingRight: "20px",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                        }}
                        htmlFor="pounchout"
                      >
                        Pounch Out
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="time"
                        className="form-control"
                        step="3600000"
                        id="pounchout"
                        value={updatePunchOut}
                        onChange={(e) => setUpdatePunchOut(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={(e) => onCancel(e)}
                  className="btn btn-light waves-effect"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  onClick={(e) => handelUpdateAttendance(e)}
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Save changes
                </button>
              </div>
            </div>
            {/* /.modal-content modal-dialog-centere */}
          </div>
        </form>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </>
  );
};

export default AttendanceEmploye;
